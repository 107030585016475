import moment from "moment";
import { NextSeo, WebPageJsonLd, BreadcrumbJsonLd } from "next-seo";

export function HomePageSEO({ data }) {
    const domain = data.seo[0].domain || "https://www.stdwatch.com";
    const siteName = domain
        .replace(/^(https?:\/\/)?(www\.)?/, '')
        .replace(/\/$/, '');
    const SITE_LOGO = data.seo[0].logo || "https://www.stdwatch.com/assets/design/menu/logo.png";

    return (
        <>
            <NextSeo
                title={`${data.seo[0].title}`}
                canonical={`${data.seo[0].canonicalUrl}`}
                description={`${data.seo[0].description}`}
                openGraph={{
                    url: domain,
                    title: `${data.seo[0].title}`,
                    description: `${data.seo[0].description}`,
                    images: [
                        {
                            url: data.seo[0].image.url,
                            width: 400,
                            height: 250,
                            alt: "home-page"
                        },
                    ],
                    site_name: siteName,
                }}
            />
            <WebPageJsonLd
                mainEntityOfPage={
                    {
                        "@type": "WebPage",
                        "id": domain
                    }
                }

                headline={`${data.seo[0].title}`}
                image={data.seo[0].image.url}
                datePublished={`${moment(data.createdAt).format('MMM DD, YYYY')}`}
                dateModified={`${moment(data.updatedAt).format('MMM DD, YYYY')}`}
                description={`${data.seo[0].description}`}
                publisher={{
                    "@type": "Organization",
                    "name": siteName,
                    "url":  domain,
                    "logo": {
                        "@type": "ImageObject",
                        "url":  SITE_LOGO,
                    }
                }}
            />
            <BreadcrumbJsonLd
                itemListElements={[
                    {
                        position: 1,
                        name: "Home",
                        item:  domain,
                    }
                ]}
            />
        </>
    )
}